import React from 'react';
import {Link} from 'gatsby';

import {NavItem, NavInner} from './post-nav.style'
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const PostNav = ({title, slug, image, rel}) => {
    const navImage = getImage(image);

    return (
        <NavItem rel={rel}>
            <Link to={`/case-study/${slug}`}>
                <NavInner rel={rel}>
                    <h6>{title}</h6>
                    <GatsbyImage image={navImage} alt={title}/>

                    <i className="fa fa-angle-right"></i>
                </NavInner>
            </Link>
        </NavItem>
    )
}

export default PostNav;